import { useEffect } from "react";
import { homeAnimations } from "../animation";
import About from "../components/about";
import Expertise from "../components/expertise";
import Layout from "../components/layout";
import Header from "../components/header";
import ProjectList from "../components/project-list";
import Preloader from "../components/preloader";
import Seo from "../components/seo";
import PageWrapper from "../templates/page-wrapper";
import Locomotive from "../components/locomotive";
import { isBrowser } from "../util";
// markup
const IndexPage = () => {
  let showPreloader;
  if (isBrowser) showPreloader = sessionStorage.preloader || true;
  
  useEffect(() => {
    new homeAnimations();
  }, []);

  return (
    <PageWrapper>
      <Seo title="Home" />
      {showPreloader !== "false" && <Preloader />}
      <Locomotive />
      <Layout>
        <Header />
        <About />
        <ProjectList />
        <Expertise />
      </Layout>
    </PageWrapper>
  );
};

export default IndexPage;
