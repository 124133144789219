function Header() {
  return (
    <section aria-labelledby="hero" className="hero layout">
      <div>
        <div className="hero__desc__contact hidden">
          <div data-move-in-container="">
            <p data-move-in="" className="hero__desc__contact_title">
              OLUCHI NWENYI
            </p>
          </div>
          <div data-move-in-container className="hero__desc__contact_email">
            <div data-move-in="">
              <span>EMAIL: </span>
              <a className="link-hover" href="mailto:oluchinwenyi@gmail.com">
                OLUCHINWENYI@GMAIL.com
              </a>
            </div>
          </div>
        </div>
        <div>
          <h1 id="hero" className="hero_title">
            just a girl growing <br /> wings.
          </h1>
        </div>
        <div className="hero__desc">
          <div className="hero__desc__contact hidden-small">
            <div data-move-in-container="">
              <p data-move-in="" className="hero__desc__contact_title">
                OLUCHI NWENYI
              </p>
            </div>
            <div
              data-move-in-container=""
              className="hero__desc__contact_email"
            >
              <div data-move-in="">
                <span>EMAIL: </span>
                <a className="link-hover" href="mailto:oluchinwenyi@gmail.com">
                  OLUCHINWENYI@GMAIL.com
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="hero__desc_work_desc">
              <div data-move-in-container="">
                <span className="hero__desc_work_desc_item">
                  digital product designer,
                </span>
              </div>
              <div data-move-in-container="">
                <span className="hero__desc_work_desc_item">
                  software developer, &
                </span>
              </div>
              <div data-move-in-container="">
                <span className="hero__desc_work_desc_item">
                  technical writer.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
