import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";

function Locomotive() {
    useEffect(() => {
      const scrollEl = document.querySelector("[data-scroll-container]");

      const scroll = new LocomotiveScroll({
        el: scrollEl,
        smooth: true,
        lerp: 0.035,
      });

      scroll.on("scroll", ScrollTrigger.update);

      // Exposing to the global scope for ease of use.
      window.scroll = scroll;

      ScrollTrigger.scrollerProxy(scroll.el, {
        scrollTop(value) {
          return arguments.length
            ? scroll.scrollTo(value, 0, 0)
            : scroll.scroll.instance.scroll.y;
        },

        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
      });

      scroll.update();

      return () => {
        scroll.destroy();
      };
    }, []);

  return <></>;
}

export default Locomotive;
