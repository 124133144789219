import { graphql, StaticQuery } from "gatsby";
import { Link } from "gatsby";
import PropTypes from "prop-types";

function ProjectListTemplate({ data }) {
  return (
    <section aria-label="articles" className="project layout">
      <ol className="lines--top">
        {data.map(
          (
            {
              node: {
                frontmatter: { title, role },
                fields: { slug },
              },
            },
            index
          ) => (
            <li key={index} className="project__item lines--bottom">
              <Link to={slug} className="project__item__desc" title={title}>
                <h3 className="project__item__title lines">{title}</h3>
                <p className="project__item__scope">
                  <span className="project__item__scope-inner">{role}</span>
                </p>
              </Link>
            </li>
          )
        )}
      </ol>
    </section>
  );
}

ProjectListTemplate.propTypes = {
  data: PropTypes.array,
};

export default function ProjectList() {
  return (
    <StaticQuery
      query={Query}
      render={(data) => (
        <ProjectListTemplate data={data.allMarkdownRemark.edges} />
      )}
    />
  );
}

const Query = graphql`
  query ProJectListQuery {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            role
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
