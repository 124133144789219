import { StaticImage } from "gatsby-plugin-image";

function About() {
  return (
    <section aria-labelledby="about" className="about">
      <div className="layout">
        <StaticImage
          src="https://res.cloudinary.com/victorcodes/image/upload/v1657403035/lulu-folio/flower_r8bavb.png"
          placeholder="blurred"
          alt="flower"
          className="flower_img"
          layout="fixed"
          width={100}
          height={100}
        />
        <div>
          <div className="about__heading">
            <StaticImage
              src="https://res.cloudinary.com/victorcodes/image/upload/v1661898141/significant-desktop_uzcqwo.png"
              placeholder="blurred"
              className="hidden-mobile"
              layout="fullWidth"
              alt="Making things simple, but significant."
              title="Making things simple, but significant."
            />

            <h2 id="about" className="about__title text-stroke hidden-desktop">
              Making things simple, but <br />
              <span>significant</span>.
            </h2>
          </div>
          <div className="footer_dots show">
            <div></div>
            <div></div>
          </div>
          <p className="about__desc fade-in">
            Every project is an opportunity to learn and express creativity in
            different forms. In the past two years, i have executed ideas into
            amazing projects, ACROSS DIFFERENT INDUSTRIES, that satisfy user
            needs, and business goals.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
