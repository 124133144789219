function Expertise() {
  return (
    <section aria-labelledby="expertise" className="expertise">
      <div className="layout">
        <div className="expertise__skill">
          <h2 id="expertise" className="subheader_text" data-fade-in>
            EXPERTISE / SKILLS
          </h2>
          <div>
            {skillsList.map((skill, index) => (
              <p key={index} data-fade-in="" className="expertise__skill_item">
                {skill}
              </p>
            ))}
          </div>
        </div>
        <div className="expertise__interests" data-fade-in>
          <h2 className="subheader_text">INTERESTS</h2>
          <p className="expertise__interests_desc">
            When I’m not working, I’m reading or WATCHING psych
            thrillers/romance, SCROLLING THROUGH STUDYGRAM, OR journaling on my
            ipad. <br /> I also enjoy contributing to open source projects and
            communities.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Expertise;

const skillsList = [
  "Product Design",
  "UI/UX Design",
  "Technical Writing",
  "Information Architecture",
  "API Documentation",
  "Software Documentation",
  "Software Development",
  "Developer Operations (DevOps)",
  "Cloud Engineering",
];
