import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBrowser, snapTo } from "../util";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({ duration: 1.1, ease: "power4.inOut" });
ScrollTrigger.defaults({
  scroller: "[data-scroll-container]",
});

if (isBrowser)
  window.onbeforeunload = function () {
    window.sessionStorage.clear();
  };
class homeAnimations {
  constructor() {
    this.runAnim();
  }
  runAnim() {
    let preloaderCount = sessionStorage.preloader || true;

    if (preloaderCount !== "false") {
      this.percentLoader = 0;
      this.preloader();
      sessionStorage.preloader = "false";
    } else {
      this.init();
    }
  }

  init() {
    isBrowser &&
      document
        .querySelectorAll(".cursor-circle")
        .forEach((el) => el.classList.add("is-visible"));

    gsap.utils.toArray("[data-move-in]").forEach((el) => {
      gsap.set(el, {
        opacity: 0,
        yPercent: 120,
      });

      gsap.to(el, {
        autoAlpha: 1,
        delay: 0.4,
        yPercent: 0,
      });
    });

    gsap.from(".hero_title", {
      opacity: 0,
      yPercent: 20,
      delay: 0.4,
      autoAlpha: 0,
    });

    gsap.set(".hero__desc_work_desc_item", {
      opacity: 0,
      yPercent: 120,
    });

    gsap.to(".hero__desc_work_desc_item", {
      delay: 0.4,
      opacity: 1,
      autoAlpha: 1,
      yPercent: 0,
      stagger: 0.05,
    });

    gsap.utils.toArray(".fade-in").forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
        },
        opacity: 0,
        yPercent: 25,
        ease: "power4.out",
      });
    });

    gsap.utils.toArray("[data-fade-in]").forEach((el) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
        },
        opacity: 0,
        duration: 1.5,
        ease: "power4.out",
        yPercent: 100,
      });
    });

    new SplitType(".lines", {
      types: "lines, words",
      tagName: "span",
    });

    // animation for projects section
    ScrollTrigger.saveStyles(".project__item, .lines--bottom, .lines--top");

    ScrollTrigger.matchMedia({
      //desktop
      "(min-width: 1024px)": function () {
        gsap.from(".project__item", {
          scrollTrigger: {
            trigger: ".project",
            start: "top 75%",
          },
          opacity: 0,
          stagger: 0.01,
          borderColor: "#070809",
        });
      },

      //mobile
      "(max-width: 1023px)": function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".project",
            start: "top 75%",
          },
        });
        tl.from(".lines--bottom", {
          opacity: 0,
          stagger: 0.01,
          borderBottomColor: "#070809",
        });

        tl.from(
          ".lines--top",
          {
            opacity: 0,
            borderTopColor: "#070809",
          },
          "-=1.1"
        );
      },
    });

    gsap.from(".line", {
      scrollTrigger: {
        trigger: ".project",
        start: "top 70%",
      },
      opacity: 0,
      stagger: 0.03,
      yPercent: 120,
    });

    gsap.from(".project__item__scope-inner", {
      scrollTrigger: {
        trigger: ".project",
        start: "top 70%",
      },
      opacity: 0,
      stagger: 0.03,
      yPercent: 120,
      delay: 0.03,
    });
  }
  preloader() {
    const textEl = isBrowser
      ? document.querySelector(".preloader__anim_text")
      : null;

    isBrowser &&
      document
        .querySelector("[data-scroll-container]")
        .classList.add("no-scroll");

    gsap.to(".preloader", {
      duration: 3,
      onUpdate: () => {
        if (this.percentLoader <= 100) {
          this.percentLoader += 1;
          let snapValue = `${snapTo(this.percentLoader)}%`;

          gsap.to(".preloader__progress", {
            width: snapValue,
            ease: "power2.out",
          });

          textEl.textContent = snapValue;
        }
      },
      onComplete: () => {
        isBrowser &&
          document
            .querySelector("[data-scroll-container]")
            .classList.remove("no-scroll");

        gsap.to(".preloader", {
          delay: 0.3,
          clipPath: "polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%)",
          display: "none",
          onComplete: () => {
            let preloaderCount = sessionStorage.preloader || true;
            if (preloaderCount !== "false") return;
            else this.init();
          },
        });
      },
    });
  }
}

export { homeAnimations };
