import "../assets/scss/styles.scss";
// import "../assets/locomotive.css";
import Footer from "./footer";
import Navigation from "./navigation";

function Layout({ children }) {
  return (
    <div data-scroll-container className="Layout">
      <div>
        <Navigation />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
