import Lottie from "lottie-react";
import lottieData from "../assets/lottie.json";

function Preloader() {
  return (
    <div className="preloader">
      <div>
        <div className="preloader__lottie">
          <Lottie
            autoplay
            loop={1}
            animationData={lottieData}
          />
        </div>

        <div className="preloader__anim">
          <div>
            <span className="preloader__anim_text">0%</span>
            <div className="preloader__progress" />
          </div>
          <p className="preloader_desc">HI! I’m LU.</p>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
